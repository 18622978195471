import React from 'react';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                    {/* 1st block */}
                    <div className="col-span-12 lg:col-span-4">
                        <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                            <h3 className="font-bold text-4xl mb-4">HEADQUARTERS MOVING</h3>
                            <div className='text-md font-medium text-gray-600'>
                                <a href="tel:17722073720">
                                    <h5>(772) 207-3720</h5>
                                </a><br />
                                <a href="mailto:headquartersmovers@gmail.com">
                                <h5>HeadquartersMovers@gmail.com</h5>
                                </a><br />
                                <p>Serving the Treasure Coast and all of Florida</p>
                            </div>
                        </div>
                    </div>
                    {/*<a className="text-gray-400" href="tel:17722073720">
                                    Click here to Call Us!
                                </a><br></br>
                                <a className="text-gray-400" href="sms:17722073720">
                                    Click here to Text Us!
                                </a><br></br>
                                <a className="text-gray-400" href="mailto:headquartersmovers@gmail.com">
                                    Click here to Email Us!
    </a> */}
                    

                    {/* 3rd block */}
                    <div className="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2 mx-auto">
                        <h6 className="text-[#013289] text-xl font-bold mb-4">OTHER PLATFORMS</h6>
                        <ul className="text-md">
                        {/*<li className="mb-2">
                            <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Moving</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Installation</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Space Solutions Specialist</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Other Services</Link>
                        </li>*/}
                        <li className="mb-2">
                            <Link to="https://www.google.com/search?q=Headquarters+Moving&stick=H4sIAAAAAAAA_-NgU1I1qDBJMU60TEs0NTdNS0o0SjO2MqiwSDZPSbIwNzMAiqckmRovYhX2SE1MKSxNLCpJLSpW8M0vy8xLBwBUByJcPwAAAA&hl=en&mat=CboT62px2PJoElcBmzl_pTlzEHzePD8Ll_2_ewwvikee4JOdpg_ytOJx_aAuX5tOH_me46aCEre7KXYJVw1YeidrfmtSzMi4nCrIj2ABeB5oAek-PxohlJWgX6U5er0j9M0&authuser=0#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                Google Business</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="https://www.homeadvisor.com/rated.HeadquartersMovingLLC.118783338.html" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                Find us on HomeAdvisor Powered by Angi</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="https://www.angi.com/companylist/us/fl/port-st-lucie/headquarters-moving-llc-reviews-10657889.htm" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                Angi Profile and Reviews</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="https://www.thumbtack.com/fl/port-saint-lucie/movers/headquarters-moving-llc/service/538343591400620035" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                Thumbtack</Link>
                        </li>
                        
                        </ul>
                    </div>

                    {/* Reviews block */}
                    <div className="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2 mx-auto">
                        <h6 className="text-[#013289] text-xl font-bold mb-4">REVIEWS</h6>
                        <ul className="text-md">
                        <li className="mb-2">
                            <Link to="https://g.page/r/CVPb-gl2uH2MEAI/review" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                Leave a Google Review</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="https://www.angi.com/write-review/26643364" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                Leave an Angi Review</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="https://www.homeadvisor.com/review/118783338?hired=unknown" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                                Leave a HomeAdvisor Review</Link>
                        </li>
                        </ul>
                    </div>
                    {/* 2nd block */}
                    <div className="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2 mx-auto">
                        <h6 className="text-[#013289] text-xl font-bold mb-4">LINKS</h6>
                        <ul className="text-md">
                        <li className="mb-2">
                            <HashLink smooth to="/#about" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">About</HashLink>
                        </li>
                        <li className="mb-2">
                            <HashLink smooth to="/#services" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Services</HashLink>
                        </li>
                        <li className="mb-2">
                            <HashLink smooth to="/contact#contact" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Contact</HashLink>
                        </li>                            
                        </ul>
                    </div>
                    {/* 4th block 
                    <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
                        <div className="text-xl mb-6">
                            Social Media Links.
                        </div>

                                <div className="text-md font-medium mb-6">
                                    Follow us on social media.
                                </div>
                        <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li>
                                        <Link to="#" className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                                        </svg>
                                        </Link>
                                    </li>
                                    <li className="ml-4">
                                        <Link to="#" className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                                        </svg>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                    </div>          
*/}
                    </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                    <div className="text-sm text-gray-200 font-semibold py-1">
                    Copyright &copy; {new Date().getFullYear()}{"  "}
                    <HashLink to="#" className=" hover:text-gray-900">
                        Headquarters Moving LLC
                    </HashLink>. All rights reserved.
                    </div>
                </div>
                </div>

                </div>
                
            </footer>
        </>
    )
}
export default Footer;
